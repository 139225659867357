<template>
  <div class='row' style="background:white; border-radius: 10px; box-shadow: 0.5px 0.866px 4px 0px rgba(2, 8, 2, 0.2); padding-bottom: 25px;">
    <div class='col-md-12' :class="$style.divColHomeworks">
      <div :class="$style.divHomeworks">
        <div class="row">
          <div class="col-md-12">
            <router-link :to="{ name: 'studentAssignments'}" :class="$style.customLink">
              <span class="float-left" :class="$style.title">ASSIGNMENTS</span>
              <span v-if="assignmentsCount > 4" class="float-right" :class="$style.title">Show More ({{activeLmsHomeworks.length}} / {{assignmentsCount}})</span>
              <!-- <span :class="$style.notificationNumber" v-if="lmsHomeworkCount !== 0">{{lmsHomeworkCount}}</span> -->
              
            </router-link>
          </div>
        </div>
        <div class="row" :class="$style.customTable" v-if="!lmsLoading">
          <div class="col-md-10 offset-md-1" :class="$style.divMaterialColumn">
            <div :class="$style.lmsLoading"></div>
          </div>
        </div>
        <div class="row" :class="$style.customTable" v-else-if="activeLmsHomeworks.length > 0 && timetohomeworks() && lmsLoading === true">
        <!-- <div class="row mt-2" :class="$style.customTable" v-else-if="true"> -->
            <div class="col-md-3 mt-1" v-for="activeLmsHomework in activeLmsHomeworks" :key="activeLmsHomework.scorm.scorm_id">
            <Card
            contentType='scorm'
            :scormCode='activeLmsHomework.scorm.scorm_code'
            :scormTitle='activeLmsHomework.scorm.scorm_name'
            :id='activeLmsHomework.scorm.scorm_id'
            :title='activeLmsHomework.assignment_name'
            :packageId='activeLmsHomework.assignment_id'
            :imgpath='activeLmsHomework.scorm.scorm_big_image !== "https://www.ueslms.com/uploads/scorm-big-image" ?
              activeLmsHomework.scorm.scorm_image : activeLmsHomework.scorm.scorm_big_image'
            :score='activeLmsHomework.scorm.scorm_scores.total_score'
            :date='activeLmsHomework.end_date'
            :scormDesc='activeLmsHomework.scorm.scorm_desc'
            :collPath='activeLmsHomework.scorm.collection_path'></Card>
            </div>
        </div>
        <div :class="$style.customTable" v-else>
          <div :class="$style.divMaterialColumn">
          <p :class="$style.noContent">There is no Assignment.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import Card from '@/components/Card2';
  import moment from 'moment';


  export default {
    name: 'homeworks',
    computed: {
      ...mapGetters(['courseworks', 'lmsHomeworkCount', 'courseworkCount', 'activeLmsHomeworks']),
      ...mapState(['assignments', 'assignmentsCount']),
    },
    components: {
      Card,
    },
    methods: {
      timetohomeworks() {
        // console.log('now', moment().valueOf());
        // console.log('end', moment(this.activeLmsHomeworks[0].end_date).valueOf());
        // console.log('start', moment(this.activeLmsHomeworks[0].start_date).valueOf());
        return (moment().valueOf() < moment(this.activeLmsHomeworks[0].end_date).valueOf())
        && (moment().valueOf() > moment(this.activeLmsHomeworks[0].start_date).valueOf());
      },
    },
    props: ['lmsLoading', 'googleLoading'],
  };
</script>

<style src="./style.less" lang="less" module>