module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#222222',
    secondaryColor: '#86713E',
    backgroundColor: 'whitesmoke',
  },
  apiKey: 'KeJqBkF4Se6g3cSLft96CYhpRIn5ZU5wIFVsYxECySYg461t',
};
