<template>
  <div :class="$style.headerContainer">
    <div class="d-lg-none d-xl-none" :class="$style.divHamburgerMenu">
      <nav class="navbar navbar-expand-lg" :class="$style.hamburgerMenu">
        <a data-toggle="collapse" data-target="#navbarNavAltMarkup" class="navbar-brand" href="#" :class="$style.hamburgerBrand">My Edu Class</a>
        <button class="navbar-toggler navbar-toggle collapsed" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" :class="$style.navBtn">
          <span class="navbar-toggler-icon" :class="$style.navbarIcon">
            <i class="fa fa-bars"></i>
          </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <a v-if="!isHome" :class='$style.customNavItem' class="nav-item nav-link" href="#" @click="home">
              <i class="fa fa-home fa-3x" aria-hidden="true" :class="$style.mobileIcon"></i>
              Home
            </a>
            <router-link v-if="roleType === 'student' && readyResources()" :to="{ name: 'collections'}" class="nav-item nav-link" :class='$style.customNavItem'>
              <i class="fa fa-book  fa-3x" aria-hidden="true" :class="$style.mobileIcon"></i>
              Resources
            </router-link>
            <router-link v-else-if="roleType === 'teacher'" :to="{ name: 'collections'}" class="nav-item nav-link" :class='$style.customNavItem'>
              <i class="fa fa-book  fa-3x" aria-hidden="true" :class="$style.mobileIcon"></i>
              Resources
            </router-link>
            <router-link v-if="roleType === 'student'" :to="{ name: 'library'}" class="nav-item nav-link" :class='$style.customNavItem'>
              <i class="fa fa-book  fa-3x" aria-hidden="true" :class="$style.mobileIcon"></i>
              Library
            </router-link>
            <router-link v-else-if="roleType === 'teacher'" :to="{ name: 'library'}" class="nav-item nav-link" :class='$style.customNavItem'>
              <i class="fa fa-book  fa-3x" aria-hidden="true" :class="$style.mobileIcon"></i>
              Library
            </router-link>
            <router-link :to="{ name: 'studentAssignments'}" class="nav-item nav-link" :class='$style.customNavItem' v-if="roleType === 'student'">
              <i class="fa fa-bars  fa-3x" aria-hidden="true" :class="$style.mobileIcon"></i>
              Assignments
            </router-link>
            <router-link :to="{ name: 'teacherAssignments'}" class="nav-item nav-link" :class='$style.customNavItem' v-if="roleType === 'teacher'">
              <i class="fa fa-bars  fa-3x" aria-hidden="true" :class="$style.mobileIcon"></i>
              Assignments
            </router-link>
            <router-link :to="{ name: 'assignmentRepor'}" class="nav-item nav-link" :class='$style.customNavItem' v-if="roleType === 'teacher'">
              <i class="fa fa-bars  fa-3x" aria-hidden="true" :class="$style.mobileIcon"></i>
              Report
            </router-link>
            <a class="nav-item nav-link" :class='$style.customNavItem' href="#" @click="logout">
              <i class="fa fa-sign-out fa-3x" aria-hidden="true" :class="$style.mobileIcon"></i>
              Logout
            </a>
          </div>
        </div>
      </nav>

    </div>
    <div class="container">
      <div :class="$style.divHeader" class="d-none d-lg-block d-none d-xl-block" ref="divHeader">
        <div class="row" :class="$style.divTopMenu">
          <div class="col-md-2" @click="home">
            <div :class="$style.divInnerLogo" ref="divInnerLogo">
            </div>
          </div>
          <div class="col-md-2">
            <router-link v-if="roleType === 'student' && readyResources()" :to="{ name: 'collections'}">
              <button class="btn" :class="$style.btnHome">
                <i class="fa fa-book" aria-hidden="true" :class="$style.icon"></i>
                RESOURCES
              </button>
            </router-link>
            <router-link v-else-if="roleType === 'teacher'" :to="{ name: 'collections'}">
              <button class="btn" :class="$style.btnHome">
                <i class="fa fa-book" aria-hidden="true" :class="$style.icon"></i>
                RESOURCES
              </button>
            </router-link>
          </div>
          <div class="col-md-2">
            <router-link v-if="roleType === 'student'" :to="{ name: 'library'}">
              <button class="btn" :class="$style.btnHome">
                <i class="fa fa-book" aria-hidden="true" :class="$style.icon"></i>
                LIBRARY
              </button>
            </router-link>
            <router-link v-if="roleType === 'teacher'" :to="{ name: 'library'}">
              <button class="btn" :class="$style.btnHome">
                <i class="fa fa-book" aria-hidden="true" :class="$style.icon"></i>
                LIBRARY
              </button>
            </router-link>
          </div>
          <div class="col-md-2">
            <router-link v-if="roleType === 'student'" :to="{ name: 'studentAssignments'}">
              <button class="btn" :class="$style.btnHome">
                <i class="fa fa-bars" aria-hidden="true" :class="$style.icon"></i>
                ASSIGNMENTS
              </button>
            </router-link>
            <router-link v-if="roleType === 'teacher'" :to="{ name: 'teacherAssignments'}">
              <button class="btn" :class="$style.btnHome">
                <i class="fa fa-bars" aria-hidden="true" :class="$style.icon"></i>
                ASSIGNMENTS
              </button>
            </router-link>
          </div>
          <div class="col-md-2">
            <router-link v-if="roleType === 'teacher'" :to="{ name: 'assignmentRepor'}">
              <button class="btn" :class="$style.btnHome">
                <i class="fa fa-book" aria-hidden="true" :class="$style.icon"></i>
                REPORT
              </button>
            </router-link>
          </div>
          <div class="col-md-2 dropdown">
            <button class="btn dropdown-toogle" :class="$style.btnHome" style="text-transform: uppercase;" data-toggle="modal" data-target="#exampleModalCenter5">
              {{this.userDetails && this.userDetails.first_name}} {{this.userDetails && this.userDetails.last_name}} 
            </button>
            <!-- <button class="btn dropdown-toggle" :class="$style.btnHome" style="text-transform: uppercase;" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{this.userDetails && this.userDetails.first_name}} {{this.userDetails && this.userDetails.last_name}} 
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" @click="logout">
                <i class="fa fa-power-off" aria-hidden="true" style="color:black" :class="$style.icon"></i>
                LOGOUT</a>
            </div> -->
          </div>
          <!-- <div class="col-md-2">
            <button class="btn" :class="$style.btnHome" @click="logout">
              <i class="fa fa-power-off" aria-hidden="true" :class="$style.icon"></i>
              LOGOUT
            </button>
          </div> -->
        </div>
      </div>
    </div>
    <div class="modal fade" id="exampleModalCenter5" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">My Profile</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <Profile></Profile>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            <button class="btn" :class="$style.btnHome" @click="logout">
              <i class="fa fa-power-off" aria-hidden="true" :class="$style.icon"></i>
              LOGOUT
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import tippy from 'tippy.js';
  import { mapState } from 'vuex';
  import Profile from '@/components/Profile';
  import tools from '@/tools';
  import config from '../../config';
  import services from '@/services/content';


  export default {
    name: 'headerCustom',
    data() {
      return {
        wsCode: null,
        libraryBtn: true,
        gradeReady: false,
      };
    },
    methods: {
      async readyLibrary() {
        let libs = false;
        let school_id = this.userDetails.organization.find(x => x.type === 'main').id;
        await this.userDetails.organization.filter(y => y.type === "grade").forEach(element => {
          let length = this.libraryShowClasses.filter(x => x === element.id).length;
          if(length > 0){
             this.gradeReady = true;
          }
        });
        if(school_id === 11614 || school_id === 11558 || this.gradeReady === true){
          this.libraryBtn = false;
          return 0;
        }
        if(school_id === 48710 || school_id === 66238 || this.gradeReady === true){
          // libs = this.collections.data.find(x => x  .id === 213);
          // console.log(libs);
          const res = await services.getPiriScorms(0, 999, 'reader');
          libs = res;
          this.libraryBtn = libs ? true : false ;
          return libs ? 1 : 0;
        } else if (this.collections.data) {
          libs = this.collections.data.find(x => x.id === 213);
          this.libraryBtn = libs ? true : false ;
          return libs ? 1 : 0;
        }
        // console.log('test', this.userDetails.organization.find(x => x.type === 'main').id);
        return libs;
      },
      readyResources() {
        let res = false;
        if (this.userDetails) {
          res = this.userDetails.organization.find(x => x.type === 'main').id !== 11558;
          return res ? 1 : 0;
        }
        return res;
      },
      logout() {
        tools.cookie.set(config.global_key.tokenName, '', 0);
        tools.cookie.set(config.global_key.mcourserTokenName, '', 0);
        localStorage.clear();
        if (window.location.hostname === 'localhost') {
          window.location = 'http://localhost:8080';
        } else {
          window.location = 'https://akkenglish.com';
        }
      },
      back() {
        this.$router.go(-1);
      },
      home() {
        this.$router.push('/home');
      },
      // goToScorms() {
      //   this.routerObj = {
      //     name: 'scorms',
      //     params: {
      //       id: 215,
      //       searchList: 'collections',
      //     },
      //   };
      //   this.$router.push(this.routerObj);
      // },
    },
    props: ['isHome'],
    computed: {
      ...mapState(['roleType', 'grade', 'username', 'password', 'userDetails', 'collections']),
    },
    components: {
      Profile,
    },
    mounted() {
      tippy('[title]');
    },
  };

</script>

<style src='./style.less' lang='less' module/>

<style lang="less" scoped>
  button {
    outline: 0 !important;
    border: 0 !important;
  }

  .nav-item {
    color: white !important;
  }

  .nav-item:hover {
    background-color: white;
    color: #21468b !important;
  }

  .nav-item:hover>.fa {
    color: #21468b !important;
  }

  .navbar-brand {
    margin-right: none;
  }

</style>
